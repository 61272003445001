import React from "react"
import { navigate } from "gatsby"

import request, { getUserRole } from "../../request"
import Layout from "../../components/admin/layout"
import Modal from "../../components/common/modal"
import imgPlaceholder from "../../images/placeholder.jpg"

class Card extends React.Component {
  inputRef = React.createRef()
  render() {
    const { activity, onUpdateDesc, onSetCover, onDelete } = this.props
    return (
      <div
        className={
          "ad-activity-card " +
          (activity.cover ? "ad-activity-card--active" : "")
        }
      >
        <div className="button-group">
          <button
            onClick={() => {
              // eslint-disable-next-line no-restricted-globals
              if (confirm("確定刪除此照片?")) {
                onDelete(activity)
              }
            }}
          >
            刪除
          </button>
          {!activity.cover && (
            <button onClick={() => onSetCover(activity)}>設為封面</button>
          )}
        </div>
        <div className="ad-activity-card__img">
          <a
            href={activity.image.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="" src={activity.image.url} />
          </a>
        </div>
        <input defaultValue={activity.title} ref={this.inputRef} />
        <button
          onClick={() => onUpdateDesc(activity, this.inputRef.current.value)}
        >
          更新照片描述
        </button>
      </div>
    )
  }
}
class Activity extends React.Component {
  state = {
    albumMap: {},
    activeAlbum: "",
    reveal: false,
    isAlbum: true,
  }

  formData = null
  fileRef = React.createRef()
  formRef = React.createRef()
  imgRef = React.createRef()
  albumNameRef = React.createRef()
  imageTitleRef = React.createRef()

  deleteAlbum = () => {
    const { albumMap, activeAlbum } = this.state
    const promises = []
    albumMap[activeAlbum].forEach(activity => {
      promises.push(request.deleteActivity(activity))
    })
    Promise.all(promises).then(() => {
      const newAlbumMap = JSON.parse(JSON.stringify(albumMap))
      delete newAlbumMap[activeAlbum]
      this.setState({ albumMap: newAlbumMap, activeAlbum: "" })
    })
  }

  onDelete = activity => {
    const album = this.state.albumMap[activity.album]
    const promises = []
    const isLastOne = album.length === 1
    let i = 0

    if (activity.cover && !isLastOne) {
      for (i; i < album.length; i++) {
        // find the first activity with cover: null and set it as the new cover
        if (!album[i].cover) {
          promises.push(request.putActivity(album[i].id, { cover: 1 }))
          break
        }
      }
    }
    promises.push(request.deleteActivity(activity))
    Promise.all(promises).then(() => {
      const albumMap = JSON.parse(JSON.stringify(this.state.albumMap))
      if (isLastOne) {
        delete albumMap[activity.album]
        this.setState({ albumMap, activeAlbum: "" })
      } else {
        albumMap[activity.album][i].cover = 1
        albumMap[activity.album] = albumMap[activity.album].filter(
          act => act.id !== activity.id
        )
        this.setState({ albumMap })
      }
    })
  }

  onSetCover = activity => {
    const album = this.state.albumMap[activity.album]
    let coveredActId = -1
    for (let act of album) {
      if (act.cover) {
        coveredActId = act.id
        break
      }
    }
    const p1 = request.putActivity(coveredActId, { cover: null })
    const p2 = request.putActivity(activity.id, { cover: 1 })
    Promise.all([p1, p2]).then(() => {
      const albumMap = JSON.parse(JSON.stringify(this.state.albumMap))
      let count = 0
      for (let act of albumMap[activity.album]) {
        if (act.id === coveredActId) {
          act.cover = null
          count += 1
        } else if (act.id === activity.id) {
          act.cover = 1
          count += 1
        }
        if (count === 2) break
      }
      this.setState({ albumMap })
    })
  }

  onUpdateDesc = (activity, desc) => {
    request.putActivity(activity.id, { title: desc }).then(() => {
      const albumMap = JSON.parse(JSON.stringify(this.state.albumMap))
      for (let act of albumMap[activity.album]) {
        if (act.id === activity.id) {
          act.title = desc
          return this.setState({ albumMap })
        }
      }
    })
  }

  fetchData = activeAlbum =>
    request.getActivities({ _sort: "id:DESC" }).then(res => {
      const albumMap = {}
      res.data.forEach(activity => {
        if (!(activity.album in albumMap)) {
          albumMap[activity.album] = []
        }
        albumMap[activity.album].push(activity)
      })
      this.setState({
        activeAlbum,
        albumMap,
      })
    })

  resetInputs = () => {
    this.albumNameRef.current.value = ""
    this.imageTitleRef.current.value = ""
    // the line below doesn't work, use form.reset() instead
    // this.fileRef.current.value = ""
    this.formRef.current.reset()
    this.imgRef.current.setAttribute("src", imgPlaceholder)
    this.formData = null
  }

  onFileChange = evt => {
    const reader = new FileReader()
    reader.onload = e => {
      this.imgRef.current.setAttribute("src", e.target.result)
    }
    if (evt.target.files.length !== 0) {
      const file = evt.target.files[0]
      if (!file.type.startsWith("image")) {
        alert("所選的檔案類型並非圖片")
        this.formData = null
        this.imgRef.current.setAttribute("src", imgPlaceholder)
        return
      }
      reader.readAsDataURL(file)
      this.formData = new FormData()
      this.formData.append("files", file)
    } else {
      this.formData = null
      this.imgRef.current.setAttribute("src", imgPlaceholder)
    }
  }

  componentDidMount() {
    const role = getUserRole()
    if (role === 0) return navigate("/admin/login")
    if (role !== 1) return navigate("/admin/announcement")

    this.fetchData()
  }

  render() {
    if (!getUserRole()) return null

    const { albumMap, activeAlbum, reveal, isAlbum } = this.state
    const albums = Object.keys(albumMap)
    const activities = albumMap[activeAlbum]
    const sum = activities ? activities.length : null

    return (
      <Layout>
        <div className="ad-activity">
          <div className="ad-common-controls">
            <button
              onClick={() => this.setState({ reveal: true, isAlbum: true })}
            >
              新增相簿
            </button>
            <button
              disabled={!activeAlbum}
              onClick={() => {
                // eslint-disable-next-line no-restricted-globals
                if (confirm("確定刪除此相簿?")) {
                  this.deleteAlbum()
                }
              }}
            >
              刪除相簿
            </button>
          </div>
          <hr />
          <div className="ad-common-controls" />

          <div className="ad-activity-tabs">
            {albums.map(album => (
              <div
                key={albumMap[album][0].id}
                className={
                  "ad-activity-tab " +
                  (activeAlbum === album ? "ad-activity-tab--active" : "")
                }
                onClick={() => {
                  if (activeAlbum === album) {
                    this.setState({
                      activeAlbum: "",
                    })
                  } else {
                    this.setState({
                      activeAlbum: album,
                    })
                  }
                }}
              >
                {album}
              </div>
            ))}
          </div>
          {sum ? (
            <div className="ad-activity-counter">
              <button
                className={`ad-activity__new-pic ${
                  activeAlbum ? "" : "hidden"
                }`}
                onClick={() => this.setState({ reveal: true, isAlbum: false })}
              >
                新增照片
              </button>
              <span className="bold">{`${activeAlbum}`}</span>
              &nbsp;相簿共有&nbsp;
              <span className="bold">{sum}</span>
              &nbsp;張照片
            </div>
          ) : null}
          <div className="ad-activity-cards">
            {activities &&
              activities.map(activity => (
                <Card
                  key={activity.id}
                  activity={activity}
                  onDelete={this.onDelete}
                  onSetCover={this.onSetCover}
                  onUpdateDesc={this.onUpdateDesc}
                />
              ))}
          </div>
        </div>
        <Modal
          reveal={reveal}
          size="md"
          header={isAlbum ? "新增相簿" : "新增照片"}
          textConfirm="新增"
          onCancel={() => {
            this.setState({ reveal: false })
            this.resetInputs()
          }}
          onConfirm={() => {
            if (
              (isAlbum && !this.albumNameRef.current.value) ||
              !this.imageTitleRef.current.value ||
              !this.formData
            ) {
              return isAlbum
                ? alert("相簿名稱/相簿封面照/封面照描述皆須設定")
                : alert("照片/照片描述皆須設定")
            }

            if (
              isAlbum &&
              this.albumNameRef.current.value in this.state.albumMap
            ) {
              return alert("相簿名稱不得與其他相簿重複!!")
            }

            request
              .postMedia(this.formData)
              .then(res =>
                request.postActivity({
                  album: isAlbum
                    ? this.albumNameRef.current.value
                    : activeAlbum,
                  cover: isAlbum ? 1 : null,
                  title: this.imageTitleRef.current.value,
                  image: res.data,
                })
              )
              .then(res => {
                this.fetchData(res.data.album)
                this.resetInputs()
                this.setState({ reveal: false })
              })
          }}
        >
          <div className="ad-activity-modal">
            <input
              type="text"
              placeholder="請輸入相簿名稱"
              ref={this.albumNameRef}
              className={isAlbum ? "" : "invisible"}
            />
            <div className="ad-activity-modal__preview">
              <img alt="" ref={this.imgRef} src={imgPlaceholder} />
            </div>
            <button onClick={() => this.fileRef.current.click()}>
              {isAlbum ? "選擇相簿封面照" : "選擇照片"}
            </button>
            <form ref={this.formRef}>
              <input
                className="hidden"
                type="file"
                ref={this.fileRef}
                accept="image/x-png,image/gif,image/jpeg"
                onChange={this.onFileChange}
              />
            </form>
            <input
              type="text"
              placeholder={isAlbum ? "請輸入封面照描述" : "請輸入照片描述"}
              ref={this.imageTitleRef}
            />
          </div>
        </Modal>
      </Layout>
    )
  }
}

export default Activity
